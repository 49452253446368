<template>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <b-card title="Contact Details">
        <validation-observer
          ref="editContactForm"
          #default="{invalid}"
        >
          <b-form
            v-if="formData"
            class="mt-2"
            @submit.prevent="submitForm"
          >
            <b-row>
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="First Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="first_name"
                    name="First name"
                    vid="first_name"
                    rules="required"
                  >
                    <b-form-input
                      ref="first_name_input"
                      v-model="formData.first_name"
                      placeholder="First Name"
                      name="first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Last Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="last_name"
                    name="Last name"
                    vid="last_name"
                  >
                    <b-form-input
                      ref="last_name_input"
                      v-model="formData.last_name"
                      placeholder="Last Name"
                      name="last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Phone number"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="phone"
                    name="Phone number"
                    vid="phone"
                  >
                    <b-form-input
                      ref="phone_input"
                      v-model="formData.phone"
                      placeholder="Phone number"
                      name="phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="E-mail address"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="email"
                    name="E-mail address"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      ref="email_input"
                      v-model="formData.email"
                      placeholder="E-mail address"
                      name="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  class="align-items-center"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Subscribed to communication"
                  label-for="subscribed-to-communication"
                >
                  <b-form-checkbox
                    v-model="formData.subscribed_to_communication"
                    name="subscribed-to-communication"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <div class="h4 pt-2 mb-2">
                  Service
                </div>
              </b-col>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  class="align-items-center"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Scheduled for a visit"
                  label-for="scheduled-for-a-visit"
                >
                  <b-form-checkbox
                    v-model="scheduledForVisit"
                    name="scheduled-for-a-visit"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="scheduledForVisit && locationsOptions.length !== 1"
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Location"
                  label-for="location"
                >
                  <b-form-select
                    v-model="selectedLocation"
                    :options="locationsOptions"
                    @change="filterUpcomingServicesByLocationId"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="scheduledForVisit && (selectedLocation || locationsOptions.length === 1)"
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Services"
                  label-for="Services"
                >
                  <b-form-select
                    v-model="selectedService"
                    :options="filteredUpcomingServicesOptions"
                    @change="onUpcomingServiceChange"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-card-footer class="no-padding mt-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-between"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    type="reset"
                    class="mt-2"
                    @click.prevent="cancel"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2"
                    :disabled="invalid || isSubmitting || isLoadingUpcomingServices || (scheduledForVisit && selectedService === null)"
                    type="submit"
                  >
                    Save changes
                  </b-button>

                </b-col>
              </b-row>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCardFooter, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import store from '@/store'
import router from '@/router'
import { cloneDeep } from 'lodash'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: [
    'contactId',
  ],
  data() {
    return {
      isSubmitting: false,
      isLoadingUpcomingServices: true,
      formData: null,
      required,
      email,
      scheduledForVisit: false,
      locations: [],
      locationsOptions: [],
      selectedLocation: null,
      upcomingServices: [],
      upcomingServicesOptions: [],
      filteredUpcomingServicesOptions: [],
      selectedService: null,
      userChangedService: false,
    }
  },
  created() {
    if (this.contactId === 'new') {
      this.formData = {}
    } else {
      this.getContactData(this.contactId)
    }
    this.getLocations()
    this.getUpcomingServices()
  },
  methods: {
    selectScheduledVisits() {
      if (this.formData && this.formData.visit_at !== null && typeof this.formData.visit_at !== 'undefined') {
        this.scheduledForVisit = true
        this.selectedLocation = this.formData.location_id
        this.filterUpcomingServicesByLocationId()
        this.selectedService = this.getServiceKey(this.formData)
        if (this.isScheduledVisitFromPast()) {
          this.setPastVisitInFilteredOptions()
        }
      }
    },
    setPastVisitInFilteredOptions() {
      if (!this.filteredUpcomingServicesOptions.find(element => element.value === 'past')) {
        this.filteredUpcomingServicesOptions.splice(1, 0, {
          value: 'past',
          text: `${this.$options.filters.formatDateReturnDayAndMonthOnly(this.formData.visit_at)}, ${this.formData.visit_time}, ${this.formData.service_name}`,
          disabled: true,
        })
      }
      this.selectedService = 'past'
    },
    isScheduledVisitFromPast() {
      return !this.filteredUpcomingServicesOptions.find(element => element.value === this.getServiceKey(this.formData))
    },
    isPastVisitComeFromSelectedLocation() {
      return this.selectedLocation === this.formData.location_id
    },
    async getContactData(contactId) {
      const self = this
      self.isSubmitting = true
      try {
        await AccountService.getContact(contactId)
          .then(res => {
            self.formData = res.data.data
            self.formData.subscribed_to_communication = this.formData.subscribed_to_communication === 1
            self.selectScheduledVisits()
          })
      } catch (error) {
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },
    async getLocations() {
      this.locations = await cloneDeep(store.getters['auth/locations'])
      this.locationsOptions = this.locations.map(currentValue => ({
        value: currentValue.id,
        text: currentValue.name,
      }))
    },
    getServiceKey(service) {
      return `${service.recurring_event_id}/${service.date}/${service.single_event_id}`
    },
    async getUpcomingServices() {
      const self = this

      try {
        await AccountService.getUpcomingServices()
          .then(res => {
            const { data } = res.data
            self.upcomingServices = data
            self.upcomingServicesOptions = data.map(currentValue => ({
              value: self.getServiceKey(currentValue),
              text: `${self.$options.filters.formatDateReturnDayAndMonthOnly(currentValue.date)}, ${currentValue.time}, ${currentValue.name}`,
              location_id: currentValue.location_id,
              recurring_event_id: currentValue.recurring_event_id,
              date: currentValue.date,
              single_event_id: currentValue.single_event_id,
            }))
            self.isLoadingUpcomingServices = false
            if (this.locations.length === 1) {
              this.selectedLocation = this.locations[0].id
              self.filterUpcomingServicesByLocationId()
            }
            self.selectScheduledVisits()
          })
      } catch (error) {
        console.error(error)
      }
    },
    filterUpcomingServicesByLocationId() {
      const locationId = this.selectedLocation
      this.filteredUpcomingServicesOptions = this.upcomingServicesOptions.filter(element => element.location_id === locationId)
      this.filteredUpcomingServicesOptions.unshift({
        value: null,
        text: 'Select...',
      })
      if (this.formData.visit_at && !this.userChangedService && this.isScheduledVisitFromPast() && this.isPastVisitComeFromSelectedLocation()) {
        this.setPastVisitInFilteredOptions()
      } else {
        this.selectedService = null
      }
    },
    onUpcomingServiceChange() {
      this.userChangedService = true
    },
    async submitForm() {
      const self = this

      self.$refs.editContactForm.validate()
        .then(async success => {
          if (success) {
            self.isSubmitting = true
            const data = {
              first_name: self.formData.first_name,
              last_name: self.formData.last_name,
              phone: self.formData.phone,
              email: self.formData.email,
              subscribed_to_communication: self.formData.subscribed_to_communication === true ? 1 : 0,
            }

            if (self.scheduledForVisit === true && self.selectedService !== 'past') {
              const selectedServiceData = self.upcomingServicesOptions.find(element => element.value === self.selectedService)
              data.recurring_event_id = selectedServiceData.recurring_event_id
              data.date = selectedServiceData.date
              data.single_event_id = selectedServiceData.single_event_id
            } else if (self.scheduledForVisit === false) {
              data.recurring_event_id = null
              data.date = null
              data.single_event_id = null
              data.no_visit = true
            }

            try {
              if (self.contactId !== 'new') {
                await AccountService.updateContact(self.contactId, data)
              } else {
                await AccountService.createContact(data)
              }
              self.$router.go(-1)
            } catch (error) {
              console.error(error)
              handleValidationError(error, self)
            } finally {
              self.isSubmitting = false
            }
          }
        })
    },
    cancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.no-padding {
  padding: 0;
}
</style>
